import { CruiseCard } from "./CruiseCard";
import type { ItineraryData } from "../types/cruise";
import { useEffect, useState } from "react";
import { CruiseDetails } from "./CruiseDetails";

interface CruiseListProps {
  cruises: ItineraryData[];
}

export function CruiseList({ cruises }: CruiseListProps) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const [activeCruise, setActiveCruise] = useState<ItineraryData | null>(null);

  useEffect(() => {
    if (activeCruise) {
      setIsDetailsOpen(true);
    }
  }, [activeCruise]);

  const handleCloseDetails = () => {
    setActiveCruise(null);
    setIsDetailsOpen(false);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <h2 className="sr-only">{cruises.length} Search Results</h2>
      {cruises.map((cruise) => (
        <CruiseCard
          key={cruise.routeIdentifier}
          cruise={cruise}
          setCruise={setActiveCruise}
        />
      ))}
      <CruiseDetails
        cruise={activeCruise}
        isOpen={isDetailsOpen}
        onClose={handleCloseDetails}
      />
    </div>
  );
}
