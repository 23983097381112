import { useRef } from "react";
import { useRangeCalendar } from "@react-aria/calendar";
import { CalendarGrid } from "./calendarGrid";
import { CalendarHeader } from "./calendarHeader";
import type { RangeCalendarState } from "@react-stately/calendar";
import type { CalendarDate } from "@internationalized/date";

interface RangeCalendarProps {
  state: RangeCalendarState;
  value?: {
    start: CalendarDate;
    end: CalendarDate;
  };
  onChange?: (value: { start: CalendarDate; end: CalendarDate }) => void;
  minValue?: CalendarDate;
  maxValue?: CalendarDate;
  defaultValue?: {
    start: CalendarDate;
    end: CalendarDate;
  };
}

export function RangeCalendar({ state, ...props }: RangeCalendarProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
    props,
    state,
    ref,
  );

  return (
    <div
      {...calendarProps}
      ref={ref}
      className="inline-block overflow-hidden rounded-xl bg-gray-900/90 p-3 text-gray-100 shadow-lg backdrop-blur-sm"
    >
      <CalendarHeader
        state={state}
        calendarProps={calendarProps}
        prevButtonProps={prevButtonProps}
        nextButtonProps={nextButtonProps}
      />
      <div className="flex flex-col gap-8 md:flex-row">
        <CalendarGrid state={state} />
        <CalendarGrid
          state={state}
          offset={{ months: 1 }}
          className="hidden md:block"
        />
      </div>
    </div>
  );
}
