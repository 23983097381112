import ReactMarkdown from "react-markdown";
import { Calendar } from "lucide-react";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { Footer } from "./Footer";

const changelogContent = `

## [WIP] - Not released yet

### Features
- Support for more cruise lines

### Improvements
- More filters for cruise searches
- Better redirects to official cruise line websites for bookings.
- Explanations on how to book on the official cruise line websites.

### Bug fixes
-
------

## [v0.1.2] - 2024-12-12

### Features
- Added support for specifying departure dates

------

## [v0.1.1] - 2024-12-09

### Features
- Added Celebrity cruises

------

## [v0.1.0] - 2024-12-08

### Features
- Initial release of Cruise Finder
- Support for Royal Caribbean, Carnival, Norwegian & MSC cruise lines
`;

export function Changelog() {
  return (
    <div className="min-h-screen bg-gray-950">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-900/20 via-gray-950 to-gray-950" />

      <div className="container mx-auto min-h-screen px-4 py-16">
        <div className="mb-12 flex items-center justify-center">
          <Calendar className="mr-3 h-8 w-8 text-blue-400" />
          <h1 className="bg-gradient-to-r from-white to-gray-400 bg-clip-text text-4xl font-bold text-transparent">
            Changelog
          </h1>
        </div>

        <div className="glass-card prose prose-invert mx-auto max-w-3xl rounded-2xl p-8 prose-headings:text-gray-100 prose-p:text-gray-300 prose-strong:text-gray-200 prose-ul:text-gray-300">
          <ReactMarkdown
            components={{
              h1: "h2",
            }}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {changelogContent}
          </ReactMarkdown>
        </div>
      </div>
      <Footer />
    </div>
  );
}
