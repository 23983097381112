import { Anchor, Waves } from "lucide-react";
import type { ItineraryData } from "../../types/cruise";

interface PortsTimelineProps {
  portsOfCall: ItineraryData["portsOfCall"];
}

export function PortsTimeline({ portsOfCall }: PortsTimelineProps) {
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold text-gray-200">Ports of Call</h3>
      <div className="space-y-4">
        {portsOfCall.map((port, index) => (
          <div key={`${port.code}-${index}`} className="flex items-start gap-4">
            <div className="flex flex-col items-center">
              <div className="w-8 h-8 rounded-full bg-blue-500/20 border border-blue-500/50 flex items-center justify-center">
                {port.code === "SEADAY" ? (
                  <Waves className="w-4 h-4 text-blue-400" />
                ) : (
                  <Anchor className="w-4 h-4 text-blue-400" />
                )}
              </div>
              {index < portsOfCall.length - 1 && port.code !== "SEADAY" ? (
                <div className="w-0.5 h-8 bg-gradient-to-b from-blue-500/50 to-transparent" />
              ) : (
                <div className="w-0.5 h-4" />
              )}
            </div>
            <div>
              <h4 className="font-medium text-gray-200">{port.title}</h4>
              {port.code !== "SEADAY" && (
                <p className="text-sm text-gray-400">Port Code: {port.code}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
