import React, { useEffect, useRef } from "react";
import { X } from "lucide-react";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function Drawer({ isOpen, onClose, children }: DrawerProps) {
  const drawerRef = useRef<HTMLDivElement>(null);

  // Add focus trap effect
  useEffect(() => {
    if (!isOpen) return;

    const drawer = drawerRef.current;
    if (!drawer) return;

    const focusableElements = drawer.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }

      if (e.key === "Escape") {
        onClose();
      }
    };

    // Focus first element when drawer opens
    firstElement?.focus();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, onClose]);

  // Add effect to handle body scroll
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup function to restore scroll when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      {/* Backdrop */}
      <div
        className={`fixed inset-0 z-40 bg-black/60 backdrop-blur-sm transition-opacity ${
          isOpen ? "opacity-100" : "pointer-events-none opacity-0"
        }`}
        onClick={onClose}
      />

      {/* Drawer */}
      <div
        ref={drawerRef}
        className={`drawer fixed right-0 top-0 z-50 h-[100dvh] w-full transform border-l border-gray-800/50 bg-gray-950 shadow-xl transition-transform duration-300 ease-in-out md:w-[600px] ${
          isOpen
            ? "translate-y-10 overflow-hidden rounded-t-xl md:translate-x-0 md:translate-y-0 md:rounded-t-none"
            : "translate-y-full md:translate-x-full md:translate-y-0"
        }`}
      >
        <button
          onClick={onClose}
          className="absolute right-2 top-2 z-50 rounded-full p-2 mix-blend-plus-lighter transition-colors hover:bg-gray-800/50 md:right-4 md:top-4"
        >
          <X className="h-6 w-6 text-gray-400" />
        </button>
        <div className="h-full overflow-y-auto pb-10 md:!pb-0">{children}</div>
      </div>
    </>
  );
}
