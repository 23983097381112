import { useState } from "react";
import { Link, Mail } from "lucide-react";

export function Footer() {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus("loading");
    setErrorMessage("");

    const formData = new FormData();
    formData.append("email", email);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_CRUISES_API_URL}/api/v2/email-signup`,
        {
          method: "POST",
          body: formData,
        },
      );

      const data = await response.json();

      if (data.error) {
        setStatus("error");
        setErrorMessage(data.error);
      } else if (data.success) {
        setStatus("success");
        setEmail("");
      }
    } catch (e) {
      console.error(e);
      setStatus("error");
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <footer className="glass-card mt-auto rounded-t-xl p-12">
      <h2 className="sr-only">Footer</h2>
      <div className="container">
        <div className="flex flex-col justify-between gap-12 md:flex-row md:justify-between">
          <div className="flex w-full max-w-md flex-col text-gray-400">
            <div className="mb-4 flex items-center gap-3">
              <div className="flex h-10 w-10 items-center justify-center rounded-full border border-blue-500/50 bg-blue-500/20">
                <Link className="h-5 w-5 text-blue-400" />
              </div>
              <h3 className="text-lg font-medium text-gray-200">Links</h3>
            </div>
            <div className="flex flex-col gap-2">
              <a href="/" className="transition-colors hover:text-blue-400">
                Home
              </a>
              <a
                href="/search"
                className="transition-colors hover:text-blue-400"
              >
                Search
              </a>
              <a
                href="/changelog"
                className="transition-colors hover:text-blue-400"
              >
                Changelog
              </a>
              <a
                href="mailto:cruises@leonvdw.nl"
                rel="noopener noreferrer"
                target="_blank"
                className="transition-colors hover:text-blue-400"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="w-full max-w-md">
            <div className="mb-4 flex items-center gap-3">
              <div className="flex h-10 w-10 items-center justify-center rounded-full border border-blue-500/50 bg-blue-500/20">
                <Mail className="h-5 w-5 text-blue-400" />
              </div>
              <h3 className="text-lg font-medium text-gray-200">
                Stay Updated
              </h3>
            </div>

            <p className="mb-6 text-gray-400">Sign up for our newsletter</p>

            {status === "success" ? (
              <div className="py-2 text-green-400">Thanks for signing up!</div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  className="input-field w-full"
                  required
                />

                {status === "error" && (
                  <div className="text-sm text-red-400">{errorMessage}</div>
                )}

                <button
                  type="submit"
                  disabled={status === "loading"}
                  className="btn-primary flex w-full items-center justify-center gap-2"
                >
                  {status === "loading" ? (
                    <div className="h-5 w-5 animate-spin rounded-full border-2 border-white/30 border-t-white" />
                  ) : (
                    "Subscribe"
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}
