import { useCalendarGrid } from "@react-aria/calendar";
import {
  getWeeksInMonth,
  endOfMonth,
  CalendarDate,
} from "@internationalized/date";
import { useLocale } from "@react-aria/i18n";
import { CalendarCell } from "./calendarCell";
import { RangeCalendarState } from "@react-stately/calendar";
import { cn } from "@/lib/utils";

interface CalendarGridProps {
  state: RangeCalendarState;
  offset?: { months?: number };
  className?: string;
}

export function CalendarGrid({
  state,
  offset = {},
  className = "",
}: CalendarGridProps) {
  const { locale } = useLocale();
  const startDate = state.visibleRange.start.add(offset) as CalendarDate;
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state,
  );

  const [first, ...rest] = weekDays;
  const sortedWeekDays = [...rest, first];

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(startDate, locale);

  return (
    <table {...gridProps} cellPadding="0" className={cn("flex-1", className)}>
      <thead {...headerProps} className="text-gray-600">
        <tr>
          {sortedWeekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
