import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Analytics } from "@vercel/analytics/react";
import { PostHogProvider } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";

const options = {
  api_host: import.meta.env.VITE_POSTHOG_API_HOST,
  person_profiles: "always",
} as Partial<PostHogConfig>;

Sentry.init({
  dsn: "https://5bfbe170477c45eb1d991e264c1fe368@o4506565245140992.ingest.us.sentry.io/4508429422362624",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: "system",
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "https://cruises.leonvdw.nl",
    "https://www.cruisello.com",
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
      options={options}
    >
      <RouterProvider router={router} />
      <Analytics />
    </PostHogProvider>
  </StrictMode>,
);
