import { Bed } from "lucide-react";
import type { ItineraryData } from "../types/cruise";
import { getCurrencySymbol } from "../utils/currency";

interface CabinDetailsProps {
  cruise: ItineraryData;
}

export function CabinDetails({ cruise }: CabinDetailsProps) {
  const sailing = cruise.sailings[0];

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold text-gray-200">Available Cabins</h3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {sailing.staterooms.map((stateroom, index) => {
          const priceInfo = stateroom.priceInfo[0];

          if (!priceInfo) return null;

          return (
            <div
              key={`${stateroom.type}-${index}`}
              className="glass-card relative rounded-xl p-6 pb-10 transition-all duration-300 hover:border-blue-500/50"
            >
              <div className="mb-6 flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded-full border border-blue-500/50 bg-blue-500/20">
                    <Bed className="h-5 w-5 text-blue-400" />
                  </div>
                  <h4 className="text-lg font-medium text-gray-200">
                    {stateroom.name}
                  </h4>
                </div>
              </div>

              <div className="mb-2">
                <div className="mb-1 text-sm text-gray-400">
                  Standard Package
                </div>
                <div className="flex items-baseline gap-2">
                  <div className="bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-3xl font-bold text-transparent">
                    {getCurrencySymbol(priceInfo.convertedCurrency)}
                    {priceInfo.convertedPriceWithTaxesAndFees.toFixed(0)}
                  </div>
                  <div className="text-sm text-gray-400">per person</div>
                </div>
                {priceInfo.convertedCurrency !== priceInfo.currency && (
                  <div className="mt-1 text-xs text-gray-500">
                    ({getCurrencySymbol(priceInfo.currency)}
                    {priceInfo.priceWithTaxesAndFees.toFixed(0)}{" "}
                    {priceInfo.currency})
                  </div>
                )}
                <div className="mt-2 text-sm text-gray-400">
                  {getCurrencySymbol(priceInfo.convertedCurrency)}
                  {priceInfo.convertedPricePerNight.toFixed(0)} per night
                </div>
              </div>

              {priceInfo.convertedAllInclusivePriceWithTaxesAndFees && (
                <div className="relative mt-6 border-t border-gray-800 pt-6">
                  <div className="mb-2 flex items-center justify-between">
                    <div className="text-sm text-gray-400">
                      All-Inclusive Package
                    </div>
                  </div>
                  <div className="flex items-baseline gap-2">
                    <div className="bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-3xl font-bold text-transparent">
                      {" "}
                      {getCurrencySymbol(priceInfo.convertedCurrency)}
                      {priceInfo.convertedAllInclusivePriceWithTaxesAndFees.toFixed(
                        0,
                      )}
                    </div>
                    <div className="text-sm text-gray-400">per person</div>
                  </div>
                  {priceInfo.allInclusivePriceWithTaxesAndFees &&
                    priceInfo.convertedCurrency !== priceInfo.currency && (
                      <div className="mt-1 text-xs text-gray-500">
                        ({getCurrencySymbol(priceInfo.currency)}
                        {priceInfo.allInclusivePriceWithTaxesAndFees.toFixed(
                          0,
                        )}{" "}
                        {priceInfo.currency})
                      </div>
                    )}
                </div>
              )}
              <a
                href={priceInfo.bookingLink}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary -mb-4 ml-auto mt-4 flex w-min px-3 py-1 text-xs"
              >
                VIEW
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
