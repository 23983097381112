export const getCurrencySymbol = (currencyCode: string) => {
  //strip spaces
  currencyCode = currencyCode.replace(/\s/g, "");

  return (
    new Intl.NumberFormat("en", {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
    })
      .formatToParts(0)
      .find((part) => part.type === "currency")?.value || currencyCode
  );
};
