import { useDateFormatter } from "@react-aria/i18n";
import { Button } from "./button";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { VisuallyHidden } from "@react-aria/visually-hidden";
import { RangeCalendarState } from "@react-stately/calendar";
import { AriaButtonProps } from "@react-types/button";
import { useMediaQuery } from "usehooks-ts";

interface CalendarHeaderProps {
  state: RangeCalendarState;
  calendarProps: { "aria-label"?: string };
  prevButtonProps: AriaButtonProps;
  nextButtonProps: AriaButtonProps;
}

export function CalendarHeader({
  state,
  calendarProps,
  prevButtonProps,
  nextButtonProps,
}: CalendarHeaderProps) {
  const monthFormatter = useDateFormatter({
    month: "long",
    timeZone: state.timeZone,
  });

  const yearFormatter = useDateFormatter({
    year: "numeric",
    timeZone: state.timeZone,
  });

  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div className="flex items-center py-4">
      <VisuallyHidden>
        <h2>{calendarProps["aria-label"]}</h2>
      </VisuallyHidden>
      <Button {...prevButtonProps}>
        <ChevronLeftIcon className="h-6 w-6" />
      </Button>
      <div className="flex-1 text-center text-sm" aria-hidden>
        <h2 aria-hidden className="text-xl font-bold">
          {monthFormatter.format(
            state.visibleRange.start.toDate(state.timeZone),
          )}
          {!isMobile && (
            <>
              {" - "}
              {monthFormatter.format(
                state.visibleRange.start
                  .add({ months: 1 })
                  .toDate(state.timeZone),
              )}
            </>
          )}{" "}
          {yearFormatter.format(
            state.visibleRange.start.toDate(state.timeZone),
          )}
        </h2>
      </div>
      <Button {...nextButtonProps}>
        <ChevronRightIcon className="h-6 w-6" />
      </Button>
    </div>
  );
}
