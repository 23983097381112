import React from "react";

interface SelectFieldProps {
  label: string;
  icon: React.ReactNode;
  value: string | null;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{ value: string | null; label: string }>;
}

export function SelectField({
  label,
  icon,
  value,
  onChange,
  options,
}: SelectFieldProps) {
  return (
    <div className="space-y-2">
      <label className="flex items-center text-sm font-medium text-gray-300 mb-2">
        <span className="text-blue-400">{icon}</span>
        <span className="ml-2">{label}</span>
      </label>
      <select
        aria-label={label}
        value={value || "All cabin types"}
        onChange={onChange}
        className="input-field"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value || ""}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
