import { Calendar, Ship, MapPin, Clock } from "lucide-react";
import { Drawer } from "./ui/Drawer";
import { PortsTimeline } from "./cruise-details/PortsTimeline";
import type { ItineraryData } from "../types/cruise";
import { CabinDetails } from "./CabinDetails";

interface CruiseDetailsProps {
  cruise: ItineraryData | null;
  isOpen: boolean;
  onClose: () => void;
}

export function CruiseDetails({ cruise, isOpen, onClose }: CruiseDetailsProps) {
  if (!cruise) return null;
  const sailing = cruise.sailings[0];

  const formatDate = (dateString: string | Date) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div className="space-y-8 p-8">
        {/* Header Image */}
        <div className="relative -mx-8 -mt-8 h-64">
          <img
            src={
              cruise.image.src ||
              "https://images.unsplash.com/photo-1548574505-5e239809ee19?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
            }
            alt={cruise.image.alt}
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-gray-950 to-transparent" />
        </div>

        {/* Title and Basic Info */}
        <div>
          <h2 className="mb-4 text-balance text-2xl font-bold text-gray-100">
            {cruise.title}
          </h2>
          <div className="grid grid-cols-2 gap-4 pr-2">
            <div className="flex items-center text-gray-300">
              <Ship className="mr-3 h-5 w-5 text-blue-400" />
              <span>{cruise.ship?.name || "Multiple Ships Available"}</span>
            </div>
            <div className="flex items-center text-gray-300">
              <Clock className="mr-3 h-5 w-5 text-blue-400" />
              <span>{cruise.duration} Days</span>
            </div>
            <div className="flex items-center text-gray-300">
              <MapPin className="mr-3 h-5 w-5 text-blue-400" />
              <span>{cruise.embarkationPort.title}</span>
            </div>
            <div className="flex items-center text-gray-300">
              <Calendar className="mr-3 h-5 w-5 text-blue-400" />
              <span>{formatDate(sailing.departureDate)}</span>
            </div>
          </div>
        </div>

        {/* Route Map
        {cruise.mapImage && (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-200">Route Map</h3>
            <img
              src={cruise.mapImage}
              alt="Cruise Route Map"
              className="w-full rounded-xl"
            />
          </div>
        )} */}

        {/* Ports Timeline */}
        <PortsTimeline portsOfCall={cruise.portsOfCall} />

        {/* Cabin Details */}
        <CabinDetails cruise={cruise} />

        {/* Price Breakdown */}
        {/* <PriceBreakdown stateroom={sailing.staterooms[0]} currency={currency} /> */}

        {/* Booking Button */}
        <div className="sticky bottom-0 -mx-8 -mb-8 border-t border-gray-800/50 bg-gray-950/80 p-6 backdrop-blur-lg">
          <a
            href={cruise.sailings[0].staterooms[0].priceInfo[0].bookingLink}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-primary flex w-full items-center justify-center text-lg"
          >
            View This Cruise
          </a>
        </div>
      </div>
    </Drawer>
  );
}
