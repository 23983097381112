import React, { useEffect } from "react";
import { Ship, Users, Banknote, Search } from "lucide-react";
import { SelectField } from "./ui/SelectField";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateRangeCalendar } from "./ui/DateRangeCalendar";

interface SearchFormProps {
  onSearch: () => void;
}

export interface SearchParams {
  guests: number;
  cabinType: string | null;
  currency: string;
  fromDate?: string;
  toDate?: string;
}

const cabinTypes = [
  { value: "", label: "All cabin types" },
  {
    value: "solo",
    label: "Solo (will fall back to inside if no solo cabins available)",
  },
  { value: "inside", label: "Inside" },
  { value: "oceanview", label: "Ocean View" },
  { value: "balcony", label: "Balcony" },
  { value: "suite", label: "Suite" },
  { value: "deluxe", label: "Deluxe" },
];

const currencies = ["USD", "EUR", "GBP"];

export function SearchForm({ onSearch }: SearchFormProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize state from URL parameters or defaults
  const [guests, setGuests] = React.useState(
    Number(searchParams.get("guests")) || 2,
  );
  const [cabinType, setCabinType] = React.useState<string>(
    searchParams.get("cabinType") || "",
  );
  const [currency, setCurrency] = React.useState(
    searchParams.get("currency") || "EUR",
  );

  const [dateRange, setDateRange] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: searchParams.get("fromDate")
      ? dayjs(searchParams.get("fromDate"), "MM-DD-YYYY").toDate()
      : null,
    endDate: searchParams.get("toDate")
      ? dayjs(searchParams.get("toDate"), "MM-DD-YYYY").toDate()
      : null,
  });

  // Update URL params and trigger search when any field changes
  useEffect(() => {
    const params: SearchParams = {
      guests,
      cabinType: cabinType || null,
      currency,
    };

    if (dateRange.startDate) {
      params.fromDate = dayjs(dateRange.startDate).format("MM-DD-YYYY");
    }
    if (dateRange.endDate) {
      params.toDate = dayjs(dateRange.endDate).format("MM-DD-YYYY");
    }

    const searchParams = new URLSearchParams();
    searchParams.set("guests", params.guests.toString());
    if (params.cabinType) searchParams.set("cabinType", params.cabinType);
    if (params.currency) searchParams.set("currency", params.currency);
    if (params.fromDate) searchParams.set("fromDate", params.fromDate);
    if (params.toDate) searchParams.set("toDate", params.toDate);

    setSearchParams(searchParams, { replace: true });
  }, [guests, cabinType, currency, dateRange, setSearchParams]);

  useEffect(() => {
    if (guests > 1 && cabinType === "solo") {
      setCabinType("");
    }
  }, [guests, cabinType]);

  const availableCabinTypes = cabinTypes.filter(
    (type) => guests === 1 || type.value !== "solo",
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    document.getElementById("search-results")?.scrollIntoView({
      behavior: "smooth",
    });
    onSearch();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="glass-card rounded-2xl p-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-4">
          <SelectField
            label="Number of Guests"
            icon={<Users className="h-5 w-5" />}
            value={guests.toString()}
            onChange={(e) => setGuests(Number(e.target.value))}
            options={[1, 2, 3, 4].map((num) => ({
              value: num.toString(),
              label: `${num} ${num === 1 ? "Guest" : "Guests"}${
                num > 2 ? " (not available for MSC)" : ""
              }`,
            }))}
          />

          <DateRangeCalendar
            label="Departure Date range"
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onStartDateChange={(date) => {
              setDateRange((prev) => ({ ...prev, startDate: date }));
            }}
            onEndDateChange={(date) => {
              setDateRange((prev) => ({ ...prev, endDate: date }));
            }}
          />

          <SelectField
            label="Cabin Type"
            icon={<Ship className="h-5 w-5" />}
            value={cabinType}
            onChange={(e) => setCabinType(e.target.value)}
            options={availableCabinTypes}
          />

          <SelectField
            label="Currency"
            icon={<Banknote className="h-5 w-5" />}
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            options={currencies.map((curr) => ({ value: curr, label: curr }))}
          />
        </div>

        <button
          type="submit"
          className="btn-primary mt-8 flex w-full items-center justify-center gap-2"
        >
          <Search className="h-5 w-5" />
          Search Cruises
        </button>
      </form>
      <div id="search-results" />
    </>
  );
}
