import { Calendar, MapPin, Ship, ArrowRight } from "lucide-react";
import type { ItineraryData } from "../types/cruise";
import { getCurrencySymbol } from "../utils/currency";

interface CruiseCardProps {
  cruise: ItineraryData;
  setCruise: (cruise: ItineraryData) => void;
}

export function CruiseCard({ cruise, setCruise }: CruiseCardProps) {
  const sailing = cruise.sailings[0];

  const formatDate = (dateString: string | Date) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <button
      className="card glass-card rounded-2xl overflow-hidden group hover:border-blue-500/50 transition-all duration-300 flex flex-col h-full"
      onClick={() => setCruise(cruise)}
    >
      <div className="relative h-56 overflow-hidden w-full">
        <img
          src={
            cruise.image.src ||
            "https://images.unsplash.com/photo-1548574505-5e239809ee19?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80"
          }
          alt={cruise.image.alt}
          className="w-full h-full object-cover brightness-75 group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-80" />
        <div className="absolute top-4 right-4 glass-card px-3 py-1 rounded-full text-sm font-medium flex items-center gap-1">
          {getCurrencySymbol(
            sailing.staterooms[0]?.priceInfo?.[0]?.convertedCurrency
          )}
          {cruise.sailings?.[0]?.staterooms?.[0]?.priceInfo?.[0]?.convertedPricePerNight.toFixed(
            0
          )}
          /pppn
        </div>
        <div className="absolute top-12 right-4 glass-card px-3 py-1 rounded-full text-sm font-medium flex items-center gap-1">
          {cruise.duration} Days
        </div>
      </div>

      <div className="flex flex-col flex-1 p-6 w-full">
        <h3 className="text-xl font-semibold mb-6 text-gray-100 text-start">
          {cruise.title}
        </h3>

        <div className="mb-auto">
          <div className="space-y-4">
            <div className="flex items-center text-gray-300">
              <Ship className="w-5 h-5 mr-3 text-blue-400" />
              <span>{cruise.ship?.name || "Multiple Ships Available"}</span>
            </div>

            <div className="flex items-center text-gray-300">
              <MapPin className="w-5 h-5 mr-3 text-blue-400" />
              <span>{cruise.embarkationPort.title}</span>
            </div>

            <div className="flex items-center text-gray-300">
              <Calendar className="w-5 h-5 mr-3 text-blue-400" />
              <span>{formatDate(sailing.departureDate)}</span>
            </div>
          </div>
        </div>

        <div className="mt-6 pt-6 border-t border-gray-800">
          <div className="flex justify-between items-end">
            <div>
              <p className="text-sm text-gray-400">Starting from</p>
              <p className="text-3xl font-bold bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">
                {getCurrencySymbol(
                  sailing.staterooms[0]?.priceInfo?.[0]?.convertedCurrency
                )}
                {sailing.staterooms[0]?.priceInfo?.[0]?.convertedPriceWithTaxesAndFees.toFixed(
                  0
                ) || "Contact for price"}
              </p>
              <p className="text-sm text-gray-400">per person</p>
            </div>
            <div className="btn-primary flex items-center gap-2 group/btn">
              View Details
              <ArrowRight className="w-4 h-4 group-hover/btn:translate-x-1 transition-transform" />
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}
