import { DateValue, parseDate } from "@internationalized/date";

export function addMonths(date: Date, months: number): Date {
  const result = new Date(date);
  result.setMonth(result.getMonth() + months);
  return result;
}

export function formatDate(date: Date): string {
  return date.toISOString().split("T")[0];
}

export function isValidDateRange(
  startDate: Date | null,
  endDate: Date | null,
): boolean {
  if (!startDate || !endDate) return false;
  return startDate <= endDate;
}

export function toDateValue(date: Date | null | undefined): DateValue | null {
  return date ? parseDate(date.toISOString().split("T")[0]) : null;
}

export function fromDateValue(date: DateValue | null): Date | null {
  if (!date) return null;
  return date.toDate("UTC");
}

export function formatDisplayDate(date: Date): string {
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}
