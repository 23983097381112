import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { Changelog } from "./components/Changelog";
import Search from "./pages/search";

export const router = createBrowserRouter([
  {
    path: "/",
    // element: <App />,
    element: <Search />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/changelog",
    element: <Changelog />,
  },
]);
