import React from "react";
import { SearchForm, type SearchParams } from "../components/SearchForm";
import { CruiseList } from "../components/CruiseList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useCruises } from "../hooks/useCruises";
import { Footer } from "../components/Footer";
import { useSearchParams } from "react-router-dom";

const queryClient = new QueryClient();

export function SearchPage() {
  const [searchParams] = useSearchParams();
  const [search, setSearch] = React.useState(false);

  const params = React.useMemo(() => {
    const guests = Number(searchParams.get("guests")) || null;
    const cabinType = searchParams.get("cabinType") || null;
    const currency = searchParams.get("currency") || null;
    const fromDate = searchParams.get("fromDate") || null;
    const toDate = searchParams.get("toDate") || null;

    if (!guests && !cabinType && !currency) return null;

    return {
      guests,
      cabinType,
      currency,
      fromDate,
      toDate,
    } as SearchParams;
  }, [searchParams]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isError,
    error,
    refetch,
  } = useCruises(params, search);

  const [isLoadingAnimation, setIsLoadingAnimation] = React.useState(false);

  React.useEffect(() => {
    if (isFetching && !data) {
      setIsLoadingAnimation(true);
    } else if (!isFetching && data) {
      setSearch(false);
    }
  }, [data, isFetching]);

  const handleSearch = () => {
    if (isFetching || isError) {
      refetch();
    } else {
      setSearch(true);
    }
  };

  return (
    <div className="flex flex-col bg-gray-950">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-blue-900/20 via-gray-950 to-gray-950" />
      <div className="flex min-h-screen flex-col">
        <header className="px-4 pb-16 pt-12">
          <div className="container mx-auto">
            <div className="mb-12 flex items-center justify-center">
              <h1 className="ml-6 text-balance bg-gradient-to-r from-white to-gray-400 bg-clip-text text-5xl font-bold text-transparent">
                Cruise Finder
                <span className="ml-2 text-nowrap bg-gradient-to-r from-white to-gray-400 bg-clip-text text-lg text-transparent opacity-70">
                  {" "}
                  v 0.1.2 (beta)
                </span>
              </h1>
            </div>
            <SearchForm onSearch={handleSearch} />
          </div>
        </header>

        <main className="container mx-auto px-4 pb-16">
          {isLoadingAnimation && !error && (
            <div className="py-12 text-center">
              <div className="mx-auto max-w-md">
                <div className="mb-4 h-2 overflow-hidden rounded-full bg-gray-700">
                  <div
                    className="h-full w-full origin-left transform rounded-full bg-blue-500"
                    style={{
                      transform: "scaleX(0)",
                      animation: `progress-bar ${data ? "0.5s" : "35s"} forwards`,
                    }}
                    onAnimationEnd={() => setIsLoadingAnimation(false)}
                  />
                </div>
                <p className="mt-6 text-lg text-gray-400">
                  Discovering your perfect cruise adventure...
                </p>
                <div className="mt-2 h-24 text-sm text-gray-500">
                  <LoadingMessages />
                </div>
              </div>
            </div>
          )}

          {isError && (
            <div className="glass-card rounded-xl border-red-500/30 px-6 py-4 text-red-400">
              {error.message}
            </div>
          )}

          {data && (
            <>
              <CruiseList cruises={data.pages.flatMap((page) => page)} />

              {hasNextPage && (
                <div className="mt-8 text-center">
                  <button
                    onClick={() => fetchNextPage()}
                    disabled={isFetching}
                    className="rounded-lg bg-blue-600 px-6 py-3 font-medium text-white hover:bg-blue-700 disabled:opacity-50"
                  >
                    {isFetching ? "Loading more..." : "Load More Cruises"}
                  </button>
                </div>
              )}
            </>
          )}

          {!isFetching && !isError && data?.pages[0].length === 0 && (
            <div className="py-12 text-center">
              <p className="text-lg text-gray-400">
                Ready to embark on your next adventure? Start your search above.
              </p>
            </div>
          )}
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default function Search() {
  return (
    <QueryClientProvider client={queryClient}>
      <SearchPage />
    </QueryClientProvider>
  );
}

function LoadingMessages() {
  const messages = [
    "Starting the search...",
    "Scanning thousands of cruise options...",
    "Still waiting for some data coming all the way from Mexico...",
    "Combining data from multiple sources...",
    "Sorting all results to find the best deals by price and availability...",
    "Almost there, preparing your results...",
    "Done!",
  ];

  const [messageIndex, setMessageIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((current) =>
        current < messages.length - 1 ? current + 1 : current,
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [messages.length]);

  return <p>{messages[messageIndex]}</p>;
}

const progressBarKeyframes = `
@keyframes progress-bar {
  0% { transform: scaleX(0); }
  15% { transform: scaleX(0.3); }
  25% { transform: scaleX(0.35); }
  35% { transform: scaleX(0.45); }
  45% { transform: scaleX(0.5); }
  55% { transform: scaleX(0.65); }
  65% { transform: scaleX(0.75); }
  75% { transform: scaleX(0.8); }
  85% { transform: scaleX(0.85); }
  95% { transform: scaleX(0.92); }
  100% { transform: scaleX(1); }
}`;

const styleSheet = document.createElement("style");
styleSheet.textContent = progressBarKeyframes;
document.head.appendChild(styleSheet);
