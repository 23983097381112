import { useState } from "react";
import { Calendar as CalendarIcon } from "lucide-react";
import { Popover, Button, DialogTrigger } from "react-aria-components";
import { formatDisplayDate } from "../../utils/date";
import { useLocale } from "@react-aria/i18n";
import {
  createCalendar,
  today,
  getLocalTimeZone,
} from "@internationalized/date";
import { toDateValue, fromDateValue } from "../../utils/date";
import { RangeCalendar } from "./calendar/rangeCalendar";
import { useRangeCalendarState } from "@react-stately/calendar";
import { useMediaQuery } from "usehooks-ts";

interface DateRangeCalendarProps {
  label: string;
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

export function DateRangeCalendar({
  label,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: DateRangeCalendarProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { locale } = useLocale();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const timeZone = getLocalTimeZone();

  const state = useRangeCalendarState({
    locale,
    createCalendar,
    value: (() => {
      const start = startDate ? toDateValue(startDate) : null;
      const end = endDate ? toDateValue(endDate) : null;
      return start && end ? { start, end } : undefined;
    })(),
    onChange: (range) => {
      if (range) {
        onStartDateChange(fromDateValue(range.start));
        onEndDateChange(fromDateValue(range.end));
        setIsOpen(false);
      }
    },
    visibleDuration: { months: isMobile ? 1 : 2 },
    minValue: today(timeZone),
  });

  const displayText = startDate
    ? endDate
      ? `${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`
      : formatDisplayDate(startDate)
    : "Select dates";

  return (
    <div className="space-y-2">
      <label className="flex items-center text-sm font-medium text-gray-300">
        <span className="text-blue-400">
          <CalendarIcon className="h-5 w-5" />
        </span>
        <span className="ml-2">{label}</span>
      </label>
      <DialogTrigger>
        <Button
          className="input-field flex w-full items-center justify-between text-left"
          aria-label={label}
          onPress={() => setIsOpen(true)}
        >
          <span className={!startDate ? "text-sm text-white" : "text-sm"}>
            {displayText}
          </span>
        </Button>

        <Popover isOpen={isOpen} onOpenChange={setIsOpen}>
          <RangeCalendar state={state} />
        </Popover>
      </DialogTrigger>
    </div>
  );
}
