import { useInfiniteQuery } from "@tanstack/react-query";

import type { SearchParams } from "../components/SearchForm";
import { ItineraryData } from "../types/cruise";

const ITEMS_PER_PAGE = 24;
const API_URL = import.meta.env.VITE_CRUISES_API_URL;
const API_KEY = import.meta.env.VITE_CRUISES_API_KEY;

export function useCruises(searchParams: SearchParams | null, search: boolean) {
  return useInfiniteQuery<
    ItineraryData[],
    Error,
    { pages: ItineraryData[][]; pageParams: number[] },
    [string, SearchParams | null],
    number
  >({
    queryKey: ["cruises", searchParams],
    queryFn: async ({ pageParam = 0 }) => {
      console.log(
        `fetching cruises with params: ${JSON.stringify(searchParams)}`,
      );
      const response = await fetch(
        `${API_URL}/api/v2/cruises/cheapest?guests=${searchParams?.guests}${
          searchParams?.cabinType ? `&cabinType=${searchParams?.cabinType}` : ""
        }${searchParams?.currency ? `&currency=${searchParams?.currency}` : ""}
        ${searchParams?.fromDate ? `&fromDate=${searchParams?.fromDate}` : ""}
        ${searchParams?.toDate ? `&toDate=${searchParams?.toDate}` : ""}
        &limitResults=${ITEMS_PER_PAGE}&skipResults=${
          pageParam * ITEMS_PER_PAGE
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch cruises");
      }

      return response.json();
    },
    enabled: !!searchParams && search,
    getNextPageParam: (lastPage, allPages) => {
      return Object.keys(lastPage).length === ITEMS_PER_PAGE
        ? allPages.length
        : undefined;
    },
    initialPageParam: 0,
  });
}
