import { useRef } from "react";
import { useButton } from "@react-aria/button";
import { useFocusRing } from "@react-aria/focus";
import { mergeProps } from "@react-aria/utils";
import { AriaButtonProps } from "@react-types/button";

interface ButtonProps extends AriaButtonProps {
  children: React.ReactNode;
  isDisabled?: boolean;
}

export function Button(props: ButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      ref={ref}
      className={`rounded-full p-2 ${
        props.isDisabled ? "cursor-not-allowed text-gray-400" : ""
      } ${
        !props.isDisabled ? "hover:bg-blue-300 active:bg-blue-400" : ""
      } outline-none ${
        isFocusVisible ? "ring-2 ring-indigo-600 ring-offset-2" : ""
      }`}
    >
      {props.children}
    </button>
  );
}
